<template>
  <v-container fluid>
    <h2 class="text-center headFaq">{{PARTNER_CONFIG.FaqHead}}</h2>
    <v-row
      class="mt-12"
      justify="space-between"
      no-gutters
    >

      <v-expansion-panels focusable>
        <v-expansion-panel
          v-for="(question,i) in FAQ_PARTNER"
          :key="i"
        >
          <v-expansion-panel-header>{{question.quest}}</v-expansion-panel-header>
          <div class="bodyFaq">
            <v-expansion-panel-content v-html="question.answe">
            </v-expansion-panel-content>
          </div>

        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["PARTNER_CONFIG", "FAQ_PARTNER"])
  },
  methods: {
    ...mapActions(["GET_PARTNER_FAQ"])
  },
  created() {
    this.GET_PARTNER_FAQ();
  }
};
</script>

<style lang="css" scoped>
.headFaq {
  margin: 25px 5px;
}
.bodyFaq {
  margin: 5px 25px;
}
</style>