<template>
  <v-container>
    <v-card class="mx-auto ">
      <div class="text-center">
        <iframe
          width="350"
          height="270"
          src="https://www.youtube.com/embed/oM4Wr5dlTi0?start=20"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe></div>

      <!-- укр версия -->
      <div v-if="PARTNER_CONFIG.language == 'ua'">
        <v-list-item>
          <v-list-item-avatar color="grey">
            <img
              src="
            https://yt3.ggpht.com/a/AATXAJx3D580IjR6qa6UEkPyJdoyliHbetsBs15lZw=s100-c-k-c0xffffffff-no-rj-mo"
              alt=""
            >
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">9 KROKÓW</v-list-item-title>
            <v-list-item-subtitle>Інтерактивний курс вивчення польської мови для тих, хто хоче працювати в Польщі</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-card-text>
          <p><strong>За 9 кроків Ви:</strong></p>
          <ul>

            <li> Будете розуміти, говорити і читати. </li>
            <li> Ставайте цінним співробітником зі знанням мови, отже, зможете більше заробляти. </li>
            <li> Економте, вартість заняття з репетитором в 2.5-3 рази вище. </li>
            <li> Комфортно навчаєтесь в місці де Вам зручно вдома, в парку або кафе. </li>
            <li> Отримуєте знання які необхідні для комфортного перебування і роботи. </li>
            <li> Навчатися не тільки з викладачем, але і з однодумцями. </li>
            <li> Можливість комунікувати з однокурсниками. Нові знайомства. </li>
            <li> Отримуєте можливість повернення частини коштів, виходячи з Ваших результатов.</li>
            <li> Стаете частиною закритого мовного клубу і продовжуєте вивчати польську навіть після закінчення курсу польської мови. </li>
            <li> Оримуєте сертифікат, який підтверджує проходження курсу з польської мови. </li>
          </ul>
        </v-card-text>
        <div class="mx-auto ">
          <v-card-actions class="justify-center ">
            <a
              class="phone-partner"
              href="https://9.teeal.pl/"
            >
              <v-btn
                x-large
                class="white--text"
                color="blue "
              >Отримати знижку на курс
              </v-btn>
            </a>

          </v-card-actions>
        </div>
      </div>
      <!-- //укр версия -->

      <!-- Русска версия -->
      <div v-if="PARTNER_CONFIG.language == 'ru'">
        <v-list-item>
          <v-list-item-avatar color="grey">
            <img
              src="
            https://yt3.ggpht.com/a/AATXAJx3D580IjR6qa6UEkPyJdoyLiHbetsBs15lZw=s100-c-k-c0xffffffff-no-rj-mo"
              alt=""
            >

          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="headline">9 KROKÓW</v-list-item-title>
            <v-list-item-subtitle>Интерактивный курс изучения польского языка для тех, кто хочет работать в Польше</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <!-- <v-img
        src="https://cdn.vuetifyjs.com/images/cards/mountain.jpg"
        height="194"
      ></v-img> -->

        <v-card-text>
          <p><strong>За 9 шагов Вы:</strong></p>
          <ul>
            <li>Будете понимать, говорить и читать.</li>
            <li>Становитесь ценным сотрудником со знанием языка, следовательно, сможете больше зарабатывать.</li>
            <li>Экономите, стоимость занятия с репетитором в 2.5-3 раза выше.</li>
            <li>Комфортно обучаетесь в месте где Вам удобно дома, в парке или кафе.</li>
            <li>Получаете знания которые необходимы для комфортного пребывания и работы.</li>
            <li>Обучаетесь не только с преподавателем, но и с единомышленниками.</li>
            <li>Возможность коммуницировать с однокурсниками. Новые знакомства.</li>
            <li>Получаете возможность возврата части денежных средств, исходя из</li>
            <li>Ваших результатов.</li>
            <li>Становитесь частью закрытого языковой клуб и продолжаете изучать польский даже после окончания курса польского языка.</li>
            <li>Получаете сертификат, который подтверждает прохождение курса по польскому языку.</li>
          </ul>
        </v-card-text>
        <div class="mx-auto ">
          <v-card-actions class="justify-center ">
            <a
              class="phone-partner"
              href="https://9.teeal.pl/"
            >
              <v-btn
                x-large
                class="white--text"
                color="blue "
              >Получить скидку на курс
              </v-btn>
            </a>

          </v-card-actions>
        </div>
      </div>
      <!-- Русска версия -->

    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["PARTNER_CONFIG"])
  }
};
</script>

<style lang="css" scoped>
.phone-partner {
  color: #ffffff;
  text-decoration: none;
}
</style>
