var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[_c('v-card',{class:`mx-auto ${_vm.PARTNER_CONFIG.theme_partner.cardVakans} `,attrs:{"width":"100%","elevation":"6","tile":"","outlined":"","color":"#385F73","dark":""}},[(_vm.ISLOAD)?_c('div',[_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto",attrs:{"type":"card","width":"100%"}})],1):_vm._e(),(!_vm.ISLOAD)?_c('v-container',[_c('v-row',{attrs:{"justify":"space-between","no-gutters":""}},[_c('v-col',{attrs:{"md":"6","xs":"12","justify":"space-between"}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.ONE_VACANSIJA.image}},[_c('v-chip',{class:_vm.ONE_VACANSIJA.isActual,attrs:{"color":"red","text-color":"white","big":""}},[_c('v-icon',[_vm._v("mdi-fire")]),_vm._v(" "+_vm._s(_vm.ONE_VACANSIJA.priezd)+" ")],1),_c('v-chip',{staticClass:"ma-1",class:_vm.ONE_VACANSIJA.isBezviz,attrs:{"color":"green","text-color":"white"}},[_c('v-icon',[_vm._v("mdi-hail")]),_vm._v(" "+_vm._s(_vm.labeles.bezvizChip)+" ")],1),_c('v-chip',{staticClass:"ma-1",class:_vm.ONE_VACANSIJA.isUa,attrs:{"color":"indigo","text-color":"white"}},[_c('v-icon',[_vm._v("mdi-text-box-check-outline")]),_vm._v(" certyficat ")],1)],1)],1),_c('v-col',{attrs:{"xs":"12","md":"5","justify":"space-between"}},[_c('div',{staticClass:"text-left"},[_c('h2',[_vm._v(" "+_vm._s(this.PARTNER_CONFIG.language == "ua" && _vm.ONE_VACANSIJA.ua_headerVakans ? _vm.ONE_VACANSIJA.ua_headerVakans : _vm.ONE_VACANSIJA.headerVakans)+" ")])]),(_vm.ONE_VACANSIJA.places)?_c('div',{staticClass:"places",class:_vm.PARTNER_CONFIG.theme_partner.priezdList},[_c('h4',{attrs:{"align":"center"},domProps:{"innerHTML":_vm._s(_vm.PARTNER_CONFIG.language == 'ua' && _vm.ONE_VACANSIJA.ua_places
                ? _vm.ONE_VACANSIJA.ua_places
                : _vm.ONE_VACANSIJA.places)}}),_c('p',{staticClass:"my-3 subtitle-2",attrs:{"align":"center"}},[_vm._v(" "+_vm._s(_vm.labeles.dateUpdatePlaces)+" "+_vm._s(_vm.GET_ALL_JSONDATA.dateUpdatePlaces)+" ")])]):_vm._e(),(
                _vm.ONE_VACANSIJA.last_priezd && _vm.ONE_VACANSIJA.isActual == 'neActual'
              )?_c('h4',{staticClass:"grey--text text-center"},[_vm._v(" "+_vm._s(`${_vm.labeles.lastPriezd} - ${_vm.ONE_VACANSIJA.last_priezd}`)+" ")]):_vm._e(),_c('div',[_c('v-card-text',{staticClass:"category"},[_c('v-icon',{class:_vm.PARTNER_CONFIG.theme_partner.iconVakans},[_vm._v("mdi-google-maps")]),_vm._v(" "+_vm._s(_vm.ONE_VACANSIJA.cyty)+" ")],1)],1),_c('div',[_c('v-card-text',{staticClass:"category"},[_c('v-icon',{class:_vm.PARTNER_CONFIG.theme_partner.iconVakans},[_vm._v("mdi-cash")]),_vm._v(" "+_vm._s(_vm.ONE_VACANSIJA.salary)+" ")],1)],1),(
                _vm.ONE_VACANSIJA.worchours &&
                _vm.ONE_VACANSIJA.worchours.trim().length > 2
              )?_c('div',[_c('v-card-text',{staticClass:"category"},[_c('v-icon',{class:_vm.PARTNER_CONFIG.theme_partner.iconVakans},[_vm._v("mdi-clock-time-four-outline")]),_vm._v(" "+_vm._s(_vm.ONE_VACANSIJA.worchours)+" ")],1)],1):_vm._e(),_c('div',[_c('v-card-text',{staticClass:"category"},[_c('v-icon',{class:_vm.PARTNER_CONFIG.theme_partner.iconVakans},[_vm._v("mdi-human-male-female")]),_vm._v(" "+_vm._s(this.PARTNER_CONFIG.language == "ua" && _vm.ONE_VACANSIJA.ua_gender ? _vm.ONE_VACANSIJA.ua_gender : _vm.ONE_VACANSIJA.gender)+" ")],1)],1)])],1),_c('v-expand-transition',[_c('div',{attrs:{"max-width":"50%"}},[_c('v-card-text',[_c('div',{staticClass:"bodyVakans",domProps:{"innerHTML":_vm._s(this.PARTNER_CONFIG.language == 'ua' && _vm.ONE_VACANSIJA.ua_vacansBody ?
                _vm.ONE_VACANSIJA.ua_vacansBody : _vm.ONE_VACANSIJA.vacansBody)}}),(_vm.ONE_VACANSIJA.imageS)?_c('div',{staticClass:"caruselImages"},[_c('v-carousel',_vm._l((_vm.ONE_VACANSIJA.imageS),function(item,i){return _c('v-carousel-item',{key:i,attrs:{"src":item,"reverse-transition":"fade-transition","transition":"fade-transition"}})}),1)],1):_vm._e(),_c('FormLid',{attrs:{"vakansija":_vm.ONE_VACANSIJA.headerVakans}}),_c('div',{staticClass:"text-right"},[(!_vm.webShareApiSupported)?_c('v-btn',{staticClass:"btnShareVib",attrs:{"fab":"","dark":""}},[_c('a',{staticStyle:{"color":"white"},attrs:{"href":`viber://forward?text=${_vm.ONE_VACANSIJA.headerVakans} ${_vm.urlSite}`}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-share-variant")])],1)]):_vm._e(),(_vm.webShareApiSupported)?_c('v-btn',{class:_vm.PARTNER_CONFIG.theme_partner.btnShare,attrs:{"fab":"","dark":""},on:{"click":function($event){return _vm.shareViaWebShare(_vm.ONE_VACANSIJA)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-share-variant")])],1):_vm._e()],1)],1)],1)])],1):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }